import React, { useEffect, useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faUserLock } from "@fortawesome/free-solid-svg-icons";
import axios from "axios";
import { Button } from "reactstrap"; // Import Button from Reactstrap

const CustomCard = ({ title, content, width }) => {
  const [userid, setUserId] = useState("");
  const [greeting, setGreeting] = useState("");
  const [fname, setFirstName] = useState("");

  useEffect(() => {
    const user_id = localStorage.getItem("user_id");

    if (user_id) {
      setUserId(user_id);
      fetchCustomer();
    }

    const today = new Date().getHours();

    if (today < 12) {
      setGreeting("Good Morning!");
    } else if (today < 16) {
      setGreeting("Good Afternoon!");
    } else if (today < 24) {
      setGreeting("Good Evening!");
    }
  }, []);

  const fetchCustomer = async () => {
    await axios
      .get(`https://enter.bulkbuyme.com/api/public/api/customerinfo/${localStorage.getItem("user_id")}`)
      .then(({ data }) => {
        const { id, first_name, last_name } = data.customer;
        setFirstName(first_name);
        localStorage.setItem("customerid", id);
      })
      .catch(({ response }) => {
        // Handle errors here
      });
  };

  const cardStyle = {
    width: width || "300px",
    borderRadius: "20px",
    border: "none",
    boxShadow: "0 4px 8px rgba(0, 0, 0, 0.2)",
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
  };

  return (
    <div className="card" style={cardStyle}>
      <div className="card-body text-center">
        {title && (
          <h3 className="card-title" style={{ fontSize: "15px", fontWeight: "bold", marginBottom: "0px", lineHeight: "32px" }}>
            <span className="typing-animation">Hello {userid ? fname : "Guest"},  {greeting}</span>
          </h3>
        )}
        <p className="card-text" style={{ marginBottom: "0px" }}>
          <span className="typing-animation">Take the first step in saving</span>
        </p>
        <div className="progress mt-3">
          <div className="progress-bar" role="progressbar" style={{ width: "50%" }} aria-valuenow="50" aria-valuemin="0" aria-valuemax="100">
            Your Savings
          </div>
        </div>
        {/* <Button style={{padding:"3px 5px"}} color="danger">View My Deals</Button> Add the button */}
      </div>
    </div>
  );
};

export default CustomCard;
