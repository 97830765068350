import React, { useEffect, useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faUserLock } from "@fortawesome/free-solid-svg-icons";
import axios from "axios";

function NavBarTop() {
  const [userid, setUserId] = useState("");
  const [greeting, setGreeting] = useState("");
  const [fname, setFirstName] = useState("");

  useEffect(() => {
    const user_id = localStorage.getItem("user_id");

    if (user_id) {
      setUserId(user_id);
      fetchCustomer();
    }

    const today = new Date().getHours();

    if (today < 12) {
      setGreeting("Good Morning");
    } else if (today < 16) {
      setGreeting("Good Afternoon");
    } else if (today < 24) {
      setGreeting("Good Evening");
    }
  }, []);

  const fetchCustomer = async () => {
    await axios
      .get(`https://enter.bulkbuyme.com/api/public/api/customerinfo/${localStorage.getItem("user_id")}`)
      .then(({ data }) => {
        const { id, first_name, last_name } = data.customer;
        setFirstName(first_name);
        localStorage.setItem("customerid", id);
      })
      .catch(({ response }) => {
        // Handle errors here
      });
  }

  return (
    <div className="custom-navbar" style={{ position: "fixed", top: 0, width: "100%", zIndex: 1000 /* Set a higher z-index */, backgroundColor: "transparent" }}>
      <div className="row" style={{ padding: "10px" }}>
        <div className="col-9" style={{ textAlign: "left", display: "flex", alignItems: "center", justifyContent: "left" }}>
          <h4 style={{ color: "#fff" }}>BuyOne</h4>
        </div>
        <div className="col-1" style={{ paddingTop: "10px" }}></div>
        {/* <div className="col-4" style={{ color: "#666" }}>
          <h6 style={{ color: "#666" }}>Hello {userid ? fname : "Guest"} !</h6>
          <span style={{ color: "#CCC" }}>{greeting}</span>
        </div> */}
        <div className="col-1" style={{ paddingTop: "10px", paddingBottom: "10px" }}>
          <a href="#/profile" style={{ color: "#fff", textDecoration: "none" }}><FontAwesomeIcon size="lg" icon={faUserLock} /></a>
        </div>
      </div>
    </div>
  );
}

export default NavBarTop;