import React, { useEffect, useState } from "react";
import { Card, CardBody, CardFooter, CardTitle, Col, Container, Input, Row, Spinner, CardImg, CardText } from "reactstrap";
import axios from 'axios';
import NavBarBottom from "../NavBarBottom";
import NavBarTop from "../NavBarTop";
import MyDealsByCom from "./MyDealsByCom";
import SignIn from "../auth/SignIn";
import { Link, useParams } from "react-router-dom";
import DealList from "./DealList";
import PopularDeals from "./PopularDeals";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowLeft, faLocation, faLocationArrow, faPhoneAlt, faClose, faTags } from "@fortawesome/free-solid-svg-icons";
import DealOptions from "./DealOptions";


function CompanieDeals() {

    const { id } = useParams()

    const [user, setUser] = useState();
    const [deals, setDeals] = useState([]);
    const [loading, setLoading] = useState(false);
    const [title, setTitle] = useState("");
    const [img, setImage] = useState("");

    useEffect(() => {

        fetchDeals()
        fetchCompany()

    }, [])

    const fetchCompany = async () => {
        await axios.get(`https://enter.bulkbuyme.com/api/public/api/restaurants/${id}`).
            then(({ data }) => {
                const { name, image } = data.restaurant
                setTitle(name)
                setImage(image)
                //console.log(data)
                setLoading(true);
            }).catch(function (error) { });
    }

    const fetchDeals = async () => {
        await axios.get(`https://enter.bulkbuyme.com/api/public/api/res_deals_2/${id}`).
            then(({ data }) => {
                setDeals(data)
                //console.log(data)
                setLoading(true);
            }).catch(function (error) {

            });
    }

    const fetchOptions = async () => {
        //alert("dsd")
    }

    return (
        <>
            <div className="cd-my-card">
                <Row className="sticky-top-bar" style={{ background: "linear-gradient(to bottom, black, transparent)", padding: "10px", position: "absolute", top: "0", zIndex: "100", width: "100%", '--bs-gutter-x': 0 }}>
                    <div className="col-1" style={{ textAlign: "center", display: "flex", alignItems: "center", justifyContent: "left" }}>
                        <a style={{ color: "#DC3545", paddingLeft: "5px" }} href="#/home"><FontAwesomeIcon size="lg" icon={faArrowLeft} /></a>
                    </div>
                    <div className="col-8">
                        <h2 style={{ textAlign: "left", marginBottom: "0px", color: "#fff" }}>Vouchers</h2>
                    </div>
                    <div className="col-3" style={{ textAlign: "right", border: "0px solid #000", display: "flex", alignItems: "center", justifyContent: "right" }}>
                        <a style={{ color: "#fff" }} href="#/home"><FontAwesomeIcon size="lg" icon={faClose} /></a>
                    </div>
                </Row>
                <CardImg top src="rest1.gif" alt="Card Image" />

                <div className="overlay-text">
                    <h2 className="heading">{title}</h2>
                </div>
                <CardBody className="custom-card-body">
                    <CardTitle><FontAwesomeIcon style={{ paddingRight: "5px" }} size="sm" icon={faPhoneAlt} />04 369 7649</CardTitle>
                    <CardText><FontAwesomeIcon style={{ paddingRight: "5px" }} size="lg" icon={faLocationArrow} />Lake Level, Icon Tower 1, Cluster M, JLT, Dubai</CardText>
                </CardBody>
            </div>

            <Container className="" style={{ borderTop: "1px solid #c1c1c1" }}>

                {/* <MyDealsByCom resid={id} style={{ marginTop: "0px" }} /> */}

                {/* <div style={{ width: "100%", overflowX: "auto", whiteSpace: "nowrap" }}>
                    <Row className="mydeal-box" style={{ display: "flex", flexWrap: "nowrap", marginTop: "0%", alignItems: "flex-start" }}>                        
                        <Col xs={3} md={3} lg={3} style={{ padding: "5px" }}>
                            <Link style={{ textDecoration: "none" }} to={`/comp/1`}>
                                <Card className="imagecard2" style={{ boxShadow: "0 2px 4px rgba(0, 0, 0, 0.1)", borderRadius: "15px" }}>
                                    <CardImg src="discount.png" alt="Image 2" style={{ maxHeight: "auto", float: "right", width: "100%", borderRadius: "15px" }} />
                                </Card>
                                <div style={{ marginLeft: "0px", marginTop: "5px", textAlign: "center" }}>
                                    <CardText style={{ marginBottom: "0px", color: "#3F494E", fontWeight: "bold" }}>Discount</CardText>
                                </div>
                            </Link>
                        </Col>
                        <Col xs={3} md={3} lg={3} style={{ padding: "5px" }}>
                            <Link style={{ textDecoration: "none" }} to={`/comp/2`}>
                                <Card className="imagecard3" style={{ boxShadow: "0 2px 4px rgba(0, 0, 0, 0.1)", borderRadius: "15px" }}>
                                    <CardImg src="buy1get1.png" alt="Image 3" style={{ maxHeight: "auto", float: "right", width: "100%", borderRadius: "15px" }} />
                                </Card>
                                <div style={{ marginLeft: "0px", marginTop: "5px", textAlign: "center" }}>
                                    <CardText style={{ marginBottom: "0px", color: "#3F494E", fontWeight: "bold" }}>Buy 1 Get 1</CardText>
                                </div>
                            </Link>
                        </Col>                      
                    </Row>
                </div>               */}
                
                <div className="row title">
                    <div className="col-12">
                        <h2>Vouchers</h2>
                    </div>
                </div>
                <div>
                    {
                        loading ?
                            deals.length > 0 && (
                                deals.map((row, key) => (
                                    <Link style={{ textDecoration: "none" }} to={`/dealcatinfo/${row.id}`}>
                                        <div className="row border border-radius-25 shadow deal-box" style={{ height: "auto", background: "linear-gradient(to right, #ccc, #ccc)" }}>
                                            <div style={{ textAlign: "center", borderRight:"2px dotted #999" }} className="col-4">
                                                <h2 style={{ color: "#999", padding: "0", marginBottom: "0px" }}>BUY 1</h2>
                                                <h2 style={{ color: "#999", padding: "0", marginBottom: "0px" }}>GET 1</h2>
                                                <h2 style={{ color: "#999", padding: "0", marginBottom: "0px", fontWeight:"bold" }}>FREE</h2>
                                            </div>
                                            <div className="col-8" style={{ textAlign: "left" }}>
                                                <h6 style={{ color: "#999", fontWeight: "bold", marginTop: "10px", marginLeft: "10px" }}>{row.title}
                                                    {row.saving && <span style={{ color: "red", fontSize: "12px" }}> ({row.saving})</span>}
                                                </h6>
                                                <h6 style={{ color: "#999", marginTop: "10px", marginLeft: "10px" }}>Valid for {row.duration_days} Days</h6>                                                
                                            </div>
                                        </div>
                                    </Link>
                                ))
                            )
                            : <Spinner animation="border" />
                    }
                </div>                  
            </Container>
            <NavBarBottom />
        </>
    )
}
export default CompanieDeals;